import React, { Component } from "react";
import ReactModal from "react-modal";
import { withRouter } from "react-router-dom";
import ModalEditFracc from "./fraccionamientoModal";
import MenusModal from "./menusModal";
import ModalDelete from "../../modals/modalDelete";
import Request from "../../../core/httpClient";
import cogoToast from "cogo-toast";
const request = new Request();
class FraccionamientosTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      id: this.props.location.state.id,
      eddit: true,
      Fraccionamientos: [],
      idFraccionamiento: 0,
      loadingFraccionamientos: false,
    };
  }
  edditFracc = (data) => {
    this.guardar(data);
  };

  delete = (id) => {
    this.handleDelete(id);
  };

  render() {
    return (
      <div className="card-table card-owners column" id="card-owners">
        <h4>Fraccionamientos Asociados </h4>
        <div className=" justify-end">
          <button
            className="btn-modal-close color-dark"
            type="button"
            onClick={this.handleOpen.bind(this)}
          >
            <i className="fas fa-plus" />
            Agregar Fraccionamiento
          </button>
        </div>
        <div className="white-space-8" />
        <div className="table-responsive">
          <table>
            <thead>
              <tr className="text-left">
                <th className="text-left">Nombre</th>
                <th className="text-left ">Estado</th>
                <th className="text-left ">Contacto</th>
                <th className="text-left">Teléfono</th>
                <th className="text-left">Email</th>
                <th className="text-center">Status</th>
                <th className="text-center">Acciones</th>
              </tr>
            </thead>

            <tbody>
              {this.state.loadingFraccionamientos ? (
                <tr>
                  <td colSpan="8">
                    <div className="row justify-center align-center">
                      <i className="fas fa-spinner fa-spin"></i>
                    </div>
                  </td>
                </tr>
              ) : (
                this.state.Fraccionamientos.map((fraccionaiento, key) => (
                  <tr key={key}>
                    <td className="text-left">{fraccionaiento.nombre}</td>
                    <td className="text-left">{fraccionaiento.estado}</td>
                    <td className="text-left">{fraccionaiento.contacto}</td>
                    <td className="text-left">{fraccionaiento.telefono}</td>
                    <td className="text-left">{fraccionaiento.correo}</td>
                    <td className="text-center">
                      {fraccionaiento.activo === 1 ? (
                        <i
                          className="fas fa-check "
                          style={{ color: "green" }}
                        />
                      ) : (
                        <i className="fas fa-times" style={{ color: "red" }} />
                      )}
                    </td>
                    <td className="text-center" style={{ paddingLeft: "35px" }}>
                      <div className="inquilino-btns btn-container align-center">
                        <ModalEditFracc
                          fraccionamiento={fraccionaiento}
                          eddit={this.edditFracc}
                        ></ModalEditFracc>
                        <button
                          type="button"
                          style={{ paddingLeft: "15px" }}
                          onClick={this.openModalDelete.bind(
                            this,
                            fraccionaiento.id_fraccionamiento
                          )}
                        >
                          <i className="fas fa-times" /> Eliminar
                        </button>
                        <MenusModal
                          id={fraccionaiento.id_fraccionamiento}
                        ></MenusModal>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <ModalDelete
          id={this.state.idFraccionamiento}
          delete={this.delete.bind(this)}
        />
        <ReactModal
          isOpen={this.state.showModal}
          className="modal modal-houses column"
          overlayClassName="overlay"
        >
          <div class="dinamic-header row justify-center">
            <div
              class="column modal-container justify-center"
              style={{ width: "90%" }}
            >
              <h3 class="title">Datos del Fracccionamiento</h3>
            </div>
            <button
              class="btn-modal-close-new justify-end"
              onClick={this.handleClose.bind(this)}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <form
            className="flex justify-center"
            onSubmit={this.handledSubmit.bind(this)}
          >
            <div className="container column">
              <div className="white-space-16" />
              <div className="column">
                <div className="input-form-content row full align-center">
                  <div className="column label-medium">
                    {" "}
                    <p>
                      <b>Nombre:</b>
                    </p>
                  </div>
                  <div className="column full">
                    <input
                      type="text"
                      name="nameFrac"
                      className="input"
                      // placeholder="Nombre del fracccionamiento"
                      required
                      minLength="4"
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="white-space-8" />
                <div className="input-form-content row full align-center">
                  <div className="column label-medium">
                    <p>
                      <b>Descripción:</b>
                    </p>
                  </div>
                  <div className="column full">
                    <input
                      type="text"
                      name="description"
                      className="input"
                      // placeholder="Descripcion de fraccionamiento"
                      required
                      minLength="4"
                      maxLength="150"
                    />
                  </div>
                </div>
                <div className="white-space-24" />
                <div className="input-form-content row full align-center">
                  <div className="column label-duo-big">
                    <p>
                      <b>Dirección:</b>
                    </p>
                  </div>
                  <div className="column full">
                    <input
                      type="text"
                      name="address"
                      className="input"
                      //placeholder="Dirección de contacto"
                      required
                      minLength="6"
                      maxLength="150"
                    />
                  </div>
                  <div className="column label-duo-normal align-end">
                    <div className="column label-duo-big">
                      <p>
                        <b>Municipio:</b>
                      </p>
                    </div>
                  </div>
                  <div className="column full">
                    <input
                      type="text"
                      name="municipality"
                      className="input"
                      // placeholder="Municipio"
                      required
                      minLength="4"
                      maxLength="80"
                    />
                  </div>
                </div>
                <div className="white-space-8" />
                <div className="input-form-content row full align-center">
                  <div className="column label-duo-big">
                    <p>
                      <b>País:</b>
                    </p>
                  </div>
                  <div className="column full">
                    <input
                      type="text"
                      name="country"
                      className="input"
                      // placeholder="Pais"
                      required
                      maxLength="20"
                    />
                  </div>
                  <div className="column label-duo-normal align-end">
                    <div className="column label-duo-big">
                      <p>
                        <b>Estado:</b>
                      </p>
                    </div>
                  </div>
                  <div className="column full">
                    <input
                      type="text"
                      name="state"
                      className="input"
                      //placeholder="Estado"
                      required
                      minLength="6"
                      maxLength="80"
                    />
                  </div>
                </div>
                <div className="white-space-8" />
                <div className="input-form-content row full align-center">
                  <div className="column label-medium">
                    <p>
                      <b>Contacto:</b>
                    </p>
                  </div>
                  <div className="column full">
                    <input
                      type="text"
                      name="contact"
                      className="input"
                      //placeholder="contacto"
                      required
                    />
                  </div>
                </div>
                <div className="white-space-8" />
                <div className="input-form-content row full align-center">
                  <div className="column label-duo-big">
                    <p>
                      <b>Teléfono:</b>
                    </p>
                  </div>
                  <div className="column full">
                    <input
                      type="text"
                      name="phone"
                      className="input"
                      //placeholder="Teléfono"
                      required
                      maxLength="24"
                    />
                  </div>
                  <div className="column label-duo-normal align-end">
                    <div className="column label-duo-big">
                      <p>
                        <b>Email:</b>
                      </p>
                    </div>
                  </div>
                  <div className="column full">
                    <input
                      type="email"
                      name="email"
                      className="input"
                      //placeholder="email"
                      required
                    />
                  </div>
                </div>
                <div className="white-space-8" />
              </div>
              <div className="white-space-32" />
              <div className="row justify-end">
                <button
                  type="button"
                  className="btn-action-cancel"
                  onClick={this.handleClose.bind(this)}
                >
                  <i className="fas fa-times" />
                  &nbsp; CANCELAR
                </button>
                <button type="submit" className="btn-action-success">
                  <i className="fas fa-check" />
                  &nbsp; GUARDAR
                </button>
              </div>
              <div class="white-space-16"></div>
            </div>
          </form>
        </ReactModal>
      </div>
    );
  }
  componentDidMount() {
    let id = this.state.id;
    this.getFraccionamientos(id);
  }
  async getFraccionamientos(id) {
    this.setState({ loadingFraccionamientos: true });
    let data = { IdAdministracion: id };

    const response = await request.post(
      "/admin/administracion/get/fraccionamientos/all",
      data
    );

    if (response && !response.error) {
      if (response.fraccionamientos && !response.empty) {
        this.setState({
          Fraccionamientos: response.fraccionamientos,
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
      });
    }
    this.setState({ loadingFraccionamientos: false });
  }
  // GUARDA FORMULARIO
  async handledSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const form = event.target;

    let data = {
      Nombre: form.nameFrac.value,
      Descripcion: form.description.value,
      Direccion: form.address.value,
      Municipio: form.municipality.value,
      Estado: form.state.value,
      Pais: form.country.value,
      Contacto: form.contact.value,
      Telefono: form.phone.value,
      email: form.email.value,
      IdAdministracion: this.state.id,
    };
    const response = await request.post(
      "/admin/administracion/fraccionamiento/create",
      data
    );

    this.setState({ showModalAddAdmin: false });
    if (response && !response.error) {
      if (response.created && !response.empty) {
        this.setState({
          showModal: false,
        });
        cogoToast.success("Fraccionamiento agregado.", {
          position: "bottom-right",
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
          loading: false,
        });
        cogoToast.error("No se pudo agregar el nuevo fraccionamiento.", {
          position: "bottom-right",
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
        loading: false,
      });
      cogoToast.error("No se pudo agregar el nuevo fraccionamiento.", {
        position: "bottom-right",
      });
    }
    this.getFraccionamientos(this.state.id);
  }
  handleOpen() {
    this.setState({ showModal: true });
  }
  handleClose() {
    this.setState({ showModal: false });
  }

  handleEnable() {
    this.setState({
      eddit: !this.state.eddit,
    });
  }
  async guardar(data) {
    const response = await request.post(
      "/admin/administracion/eddit/fraccionamiento",
      data
    );

    if (response && !response.error) {
      if (response.eddited && !response.empty) {
        this.setState({
          eddit: false,
        });
        cogoToast.success("Datos del fraccionamiento actualizados.", {
          position: "bottom-right",
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
          loading: false,
        });
        cogoToast.error(
          "No se pudieron actualizar los datos del fraccionamiento.",
          {
            position: "bottom-right",
          }
        );
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
        loading: false,
      });
      cogoToast.error(
        "No se pudieron actualizar los datos del fraccionamiento.",
        {
          position: "bottom-right",
        }
      );
    }

    let id = this.state.id;
    this.getFraccionamientos(id);
  }
  openModalDelete(id) {
    this.setState({ idFraccionamiento: id });
    let modal = document.getElementById("deleteModal");
    if (modal) {
      modal.classList.remove("hide-modal");
      modal.classList.add("show-modal");
    }
  }
  closeModalDelete() {
    let modal = document.getElementById(this.props.name || "deleteModal");

    if (modal) {
      modal.classList.remove("show-modal");
      modal.classList.add("hide-modal");
    }
  }

  async handleDelete(id) {
    let data = {
      idFraccionamiento: id,
    };
    const response = await request.post(
      "/admin/administracion/delete/fraccionamiento",
      data
    );
    this.closeModalDelete();

    if (response && !response.error) {
      if (response.deleted && !response.empty) {
        this.getFraccionamientos(this.state.id);

        this.setState({
          showModal: false,
        });
        cogoToast.success("Fraccionamiento eliminado", {
          position: "bottom-right",
        });
      } else {
        this.setState({
          empty: true,
          //message: response.message
        });
        cogoToast.error("No se pudo eliminar el fraccionamiento.", {
          position: "bottom-right",
        });
      }
    } else {
      this.setState({
        error: true,
        //message: response.message
      });
      cogoToast.error("No se pudo eliminar el fraccionamiento.", {
        position: "bottom-right",
      });
    }
  }
}

export default withRouter(FraccionamientosTable);
