import React, { Component } from "react";
import ReactModal from "react-modal";
import { withRouter } from "react-router-dom";
import ModalUserEddit from "./modalUserEddit";
import ModalDelete from "../../modals/modalDelete";

import Request from "../../../core/httpClient";
import cogoToast from "cogo-toast";
const request = new Request();

class UserTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalUser: false,
      id: this.props.location.state.id,
      eddit: true,
      usuarios: [],
      loadingUsuarios: false,
      id_usuario: 0,
    };
  }
  edditUser = (data) => {
    this.guardar(data);
  };

  delete = (id) => {
    this.handleDelete(id);
  };

  render() {
    return (
      <div className="card-table card-owners column" id="card-owners">
        <h4>Usuarios de Administración</h4>
        <div className=" justify-end">
          <button
            className="btn-modal-close color-dark"
            type="button"
            onClick={this.handleOpen.bind(this)}
          >
            <i className="fas fa-plus" />
            Agregar Usuario
          </button>
        </div>
        <div className="white-space-8" />
        <div className="table-responsive">
          <table>
            <thead>
              <tr className="text-left">
                <th className="text-left">Nombre</th>
                <th className="text-left">Usuario</th>
                <th className="text-center">Status</th>
                <th className="text-center">Acciones</th>
              </tr>
            </thead>

            <tbody>
              {this.state.loadingUsuarios ? (
                <tr>
                  <td colSpan="6">
                    <div className="row justify-center align-center">
                      <i className="fas fa-spinner fa-spin"></i>
                    </div>
                  </td>
                </tr>
              ) : (
                this.state.usuarios.map((usuario, key) => (
                  <tr key={key}>
                    <td className="text-left">{usuario.nombre}</td>
                    <td className="text-left">{usuario.usuario}</td>
                    <td className="text-center" style={{ paddingLeft: "10px" }}>
                      {usuario.status === 1 ? (
                        <i
                          className="fas fa-check "
                          style={{ color: "green" }}
                        />
                      ) : (
                        <i className="fas fa-times" style={{ color: "red" }} />
                      )}
                    </td>
                    <td className="text-center" style={{ width: "15%" }}>
                      <div className="inquilino-btns btn-container align-center">
                        <ModalUserEddit
                          usuario={usuario}
                          edditUser={this.edditUser}
                        ></ModalUserEddit>
                        <button
                          type="button"
                          onClick={this.openModalDelete.bind(
                            this,
                            usuario.id_usuario
                          )}
                          style={{ paddingLeft: "15px" }}
                        >
                          <i className="fas fa-times" /> Eliminar
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <ModalDelete
          id={this.state.id_usuario}
          delete={this.delete.bind(this)}
          name="userDeleteModal"
        />
        <ReactModal
          isOpen={this.state.showModalUser}
          className="modal modal-houses column"
          overlayClassName="overlay"
        >
          <div className="dinamic-header row justify-center">
            <div
              className="column modal-container justify-center"
              style={{ width: "90%" }}
            >
              <h3 className="title">Registrar Usuario</h3>
            </div>
            <button
              className="btn-modal-close-new justify-end"
              onClick={this.handleClose.bind(this)}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
          <form
            className="flex justify-center"
            onSubmit={this.handledSubmit.bind(this)}
          >
            <div className="container column">
              <div className="white-space-16" />
              <div className="column">
                <div className="align-center">
                  <p>
                    <b>Nombre:</b>
                  </p>

                  <input
                    type="text"
                    name="name"
                    className="input"
                    placeholder="Nombre"
                    required
                    minLength="6"
                    maxLength="100"
                  />
                </div>
                <div className="white-space-8" />
                <div className="align-center">
                  <p>
                    <b>Usuario:</b>
                  </p>
                  <input
                    type="text"
                    name="usuario"
                    className="input"
                    placeholder="Usuario"
                    required
                    minLength="6"
                    maxLength="150"
                  />
                </div>

                <div className="white-space-8" />
                <div className="align-center">
                  <p>
                    <b>password:</b>
                  </p>
                  <input
                    type="password"
                    name="password"
                    className="input"
                    placeholder="Password"
                    required
                    minLength="6"
                    maxLength="150"
                  />
                </div>
                <div className="white-space-8" />
                <div className="align-center">
                  <p>
                    <b>Perfil:</b>
                  </p>
                  <select name="perfil" id="" className="input" disabled>
                    <option value="2">administrador</option>
                  </select>
                </div>
              </div>
              <div className="white-space-32" />
              <div className="row justify-end">
                <button
                  type="button"
                  className="btn-action-cancel"
                  onClick={this.handleClose.bind(this)}
                >
                  <i className="fas fa-times" />
                  &nbsp; CANCELAR
                </button>
                <button type="submit" className="btn-action-success">
                  <i className="fas fa-check" />
                  &nbsp; Guardar
                </button>
              </div>
              <div className="white-space-16"></div>
            </div>
          </form>
        </ReactModal>
      </div>
    );
  }
  componentDidMount() {
    this.getUsuarios();
  }

  openModalDelete(id) {
    this.setState({ id_usuario: id });
    let modal = document.getElementById("userDeleteModal");
    if (modal) {
      modal.classList.remove("hide-modal");
      modal.classList.add("show-modal");
    }
  }
  closeModalDelete() {
    let modal = document.getElementById("userDeleteModal");

    if (modal) {
      modal.classList.remove("show-modal");
      modal.classList.add("hide-modal");
    }
  }

  // // TRAER TODOS LOS USUARIOS de la administracion
  async getUsuarios() {
    this.setState({ loadingUsuarios: true });
    let data = { IdAdministracion: this.state.id };

    const response = await request.post(
      "/admin/administracion/get/usuarios",
      data
    );

    if (response && !response.error) {
      if (response.usuarios && !response.empty) {
        this.setState({
          usuarios: response.usuarios,
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
      });
    }
    this.setState({ loadingUsuarios: false });
  }
  // GUARDA FORMULARIO
  async handledSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const form = event.target;

    let data = {
      Nombre: form.name.value,
      Usuario: form.usuario.value,
      Password: form.password.value,
      Perfil: parseInt(form.perfil.value),
      IdAdministracion: this.state.id,
    };

    const response = await request.post("/admin/save/user", data);
    console.log(response);

    if (response && !response.error) {
      if (response.created && !response.empty) {
        this.setState({ showModalUser: false });
        cogoToast.success("Usuario agregado.", {
          position: "bottom-right",
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
          loading: false,
        });
        cogoToast.error("No se pudo agregar el nuevo usuario.", {
          position: "bottom-right",
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
        loading: false,
      });
      cogoToast.error("No se pudo agregar el nuevo usuario.", {
        position: "bottom-right",
      });
    }
    this.getUsuarios();
  }
  handleOpen() {
    this.setState({ showModalUser: true });
  }
  handleClose() {
    this.setState({ showModalUser: false });
  }

  handleEnable() {
    this.setState({
      eddit: !this.state.eddit,
    });
  }
  async guardar(data) {
    const response = await request.post(
      "/admin/administracion/eddit/user",
      data
    );

    if (response && !response.error) {
      if (response.eddited && !response.empty) {
        this.setState({
          eddit: false,
        });
        cogoToast.success("Datos del usuario actualizados.", {
          position: "bottom-right",
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
          loading: false,
        });
        cogoToast.error("No se pudieron actualizar los datos del usuario.", {
          position: "bottom-right",
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
        loading: false,
      });
      cogoToast.error("No se pudieron actualizar los datos del usuario.", {
        position: "bottom-right",
      });
    }

    this.getUsuarios();
  }
  async handleDelete(id) {
    let data = {
      IdUsuario: id,
    };
    console.log(id);
    const response = await request.post(
      "/admin/administracion/delet/user",
      data
    );
    ////console.log('response: ', response);
    this.closeModalDelete();
    if (response && !response.error) {
      if (response.deleted && !response.empty) {
        this.getUsuarios();
        this.setState({
          eddit: false,
        });
        cogoToast.success("Usuario eliminado con éxito.", {
          position: "bottom-right",
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
          loading: false,
        });
        cogoToast.error("No fue posible eliminar el usuario.", {
          position: "bottom-right",
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
        loading: false,
      });
      cogoToast.error("No fue posible eliminar el usuario.", {
        position: "bottom-right",
      });
    }
  }
}

export default withRouter(UserTable);
