import React from "react";
import { Consumer } from "../../context";
import moment from "moment";
import Paginador from '../../components/paginador/paginador';

const PersonasTable = ({setPage, page, personas, loading}) => {
	return (
		<div className="column">
			<div className="card-table card-incidents column" id="card-owners">
			<div className="table-responsive">
				<table>
					<thead>
						<tr className="text-left">
							<th className="text-left">ID</th>
							<th className="text-left">Teléfono</th>
							<th className="text-left">Nombre</th>
							<th className="text-left">Validado</th>
							<th className="text-left">Activado</th>
						</tr>
					</thead>
					<tbody>
						{loading? 
							<tr>
								<td colSpan="5">
									<div className="row justify-center align-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
								</td>
							</tr>
							: personas.slice(((page - 1) * 12), page * 12).map((persona) => (
							<tr key={persona.id_persona}>
								<td className="text-left">
									{persona.id_persona}
								</td>
								<td className="text-left">
									{persona.telefono}
								</td>
								<td className="text-left">
									{persona.nombre}
								</td>
								<td className="text-left">
									<label className="container-check">
										<input
											type        =   "checkbox" 
											className   =   "check-option"
											checked		=	{persona.validado}
											onChange    =   {(event)=> {
												event.target.checked = !persona.validado
											}} 
										/>
										<span className="checkmark"></span>
									</label>
								</td>
								<td className="text-left">
									<label className="container-check">
										<input
											type        =   "checkbox" 
											className   =   "check-option"
											checked		=	{persona.activated}
											onChange    =   {(event)=> {
												event.target.checked = !persona.activated
											}} 
										/>
										<span className="checkmark"></span>
									</label>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<Paginador
				setPage = {(page) => setPage(page)}
				pages   = {Math.ceil(personas.length / 12)}/>
		</div>
	</div>
	);
}

export default Consumer(PersonasTable);