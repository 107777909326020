/**
 *  routes.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Manejo de todas las rutas de la aplicación
 * 	@process: 4
 */

import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// Conexión con el Context API
import { GlobalContext } from "../context";
//Navbar
import Navbar from '../components/sidebar/navbar';
// Páginas Web
import Login from '../pages/login';
import DashBoard from '../pages/dashboard';
import Owners from '../pages/owners';
import Incidents from '../pages/incidents';
import Guests from '../pages/guests';
import Houses from '../pages/houses';
import Admin from '../pages/admin';
import AdminDetail from "../pages/administracion/adminDetail";
import Notificaciones from "../pages/notificaciones";
import Personas from '../pages/personas';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={
            props => {
                const usuario = window.localStorage.getItem("AppmospheraAdmin");
                if (usuario) {
                    return <Component {...props} />;
                } else {
                    return (
                        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                    );
                }
            }
        }
    />
);

class Routes extends Component {
    render() {
        return (
            <GlobalContext>
                <BrowserRouter>
                    <div className="flex main">
                        <Navbar />
                        <div className="column full">
                            <Switch>
                                <Route path="/" exact component={Login} />
                                <Route exact path="/login" component={Login} />
                                <PrivateRoute exact path="/tablero" component={DashBoard} />
                                <PrivateRoute exact path="/viviendas" component={Houses} />
                                <PrivateRoute exact path="/propietarios" component={Owners} />
                                <PrivateRoute exact path="/incidencias" component={Incidents} />
                                <PrivateRoute exact path="/invitados" component={Guests} />
                                <PrivateRoute exact path="/admin" component={Admin} />
                                <PrivateRoute exact path="/administracion/detalle" component={AdminDetail} />
                                <PrivateRoute exact path="/notificaciones" component={Notificaciones} />
								<PrivateRoute exact path="/personas" component={Personas} />
                            </Switch>
                        </div>
                    </div>
                </BrowserRouter>
            </GlobalContext>
        );
    }
}

export default Routes;