import React, { Component } from "react";
import ReactModal from "react-modal";
import Request from "../../../core/httpClient";
const request = new Request();

class ModalUserEddit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalUserEddit: false,
      id: this.props.usuario.id_usuario,
      nombre: this.props.usuario.nombre,
      usuario: this.props.usuario.usuario,
      password: this.props.usuario.password,
      idUsuario: this.props.usuario.id_usuario,
      activo: this.props.usuario.status,
    };
  }

  render() {
    return (
      <div>
        <div className="inquilino-btns btn-container ">
          <button type="button" onClick={this.handleEddit.bind(this)}>
            <i className="fas fa-pen" /> Editar
          </button>
        </div>
        {
          <ReactModal
            isOpen={this.state.showModalUserEddit}
            className="modal modal-houses column"
            overlayClassName="overlay"
          >
            <div class="dinamic-header row justify-center">
              <div
                class="column modal-container justify-center"
                style={{ width: "90%" }}
              >
                <h3 class="title">Editar usuario</h3>
              </div>
              <button
                class="btn-modal-close-new justify-end"
                onClick={this.handleClose.bind(this)}
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <form
              className="flex justify-center"
              onSubmit={this.handledSubmit.bind(this)}
            >
              <div className="container column">
                <div className="white-space-16" />
                <div className="column">
                  <div className="align-center">
                    <p>
                      <b>Nombre:</b>
                    </p>

                    <input
                      type="text"
                      name="name"
                      value={this.state.nombre}
                      onChange={(event) =>
                        this.setState({ nombre: event.target.value })
                      }
                      className="input"
                      placeholder="Nombre"
                      required
                      minLength="6"
                      maxLength="100"
                    />
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Usuario:</b>
                    </p>
                    <input
                      type="text"
                      name="usuario"
                      value={this.state.usuario}
                      onChange={(event) =>
                        this.setState({ usuario: event.target.value })
                      }
                      className="input"
                      placeholder="Usuario"
                      required
                      minLength="6"
                      maxLength="150"
                    />
                  </div>

                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Password:</b>
                    </p>
                    <input
                      type="password"
                      name="password"
                      value={this.state.password}
                      onChange={(event) =>
                        this.setState({ password: event.target.value })
                      }
                      className="input"
                      placeholder="Password"
                      required
                      minLength="6"
                      maxLength="150"
                    />
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Perfil:</b>
                    </p>
                    <select name="perfil" id="" className="input" disabled>
                      <option value="2">administrador</option>
                    </select>
                  </div>
                </div>
                <div className="white-space-32" />
                <div className="input-form-content row full align-center justify-start">
                  <div className="column">
                    <label className="container-check">
                      <input
                        type="checkbox"
                        id="activar"
                        checked={this.state.activo === 1 ? true : false}
                        name="activo"
                        onChange={(e) =>
                          this.setState({
                            activo: this.state.activo === 1 ? 0 : 1,
                          })
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="column">
                    <h5>
                      <b>Activar usuario</b>
                    </h5>
                  </div>
                </div>
                <div className="white-space-32" />
                <div className="row justify-end">
                  <button
                    type="button"
                    className="btn-action-cancel"
                    onClick={this.handleEddit.bind(this)}
                  >
                    <i className="fas fa-times" />
                    &nbsp; CANCELAR
                  </button>
                  <button type="submit" className="btn-action-success">
                    <i className="fas fa-sync-alt"></i>
                    &nbsp; ACTUALIZAR
                  </button>
                </div>
                <div class="white-space-16"></div>
              </div>
            </form>
          </ReactModal>
        }
      </div>
    );
  }
  handleClose() {
    this.setState({ showModalUserEddit: false, disable: true });
  }
  handleEddit() {
    this.setState({ showModalUserEddit: !this.state.showModalUserEddit });
    this.getUser();
  }
  handleDelete() {
    this.getUser();
    let data = {
      IdUsuario: this.state.id,
    };

    this.props.deleteUser(data);
  }
  // traerUsuario

  async getUser() {
    let data = { IdUsuario: this.state.id };
    const response = await request.post("/admin/administracion/get/user", data);

    if (response && !response.error) {
      if (response.usuario && !response.empty) {
        this.setState({
          nombre: response.usuario[0].nombre,
          usuario: response.usuario[0].usuario,
          password: response.usuario[0].password,
          idUsuario: response.usuario[0].id_usuario,
        });
      } else {
        this.setState({ empty: true, message: response.message });
      }
    } else {
      this.setState({ error: true, message: response.message });
    }
  }
  // GUARDA FORMULARIO
  async handledSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const form = event.target;

    let data = {
      Nombre: form.name.value,
      Usuario: form.usuario.value,
      Password: form.password.value,
      activo: this.state.activo,
      Perfil: parseInt(form.perfil.value),
      IdAdministracion: this.state.id,
      IdUsuario: this.state.idUsuario,
    };
    this.setState({
      showModalUserEddit: false,
    });
    this.props.edditUser(data);
  }
}

export default ModalUserEddit;
