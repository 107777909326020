import React, { useState } from 'react';
import Helmet from 'react-helmet';
import Header from "../../components/header/header";
import Controls from '../../components/controls/controls';
import Request from '../../core/httpClient';
import cogoToast from 'cogo-toast';
import Tabla from './Tabla';

const request = new Request();

const Personas = () => {
	const [personas, setPersonas] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingSave, setLoadingSave] = useState(false);
	const [page, setPage] = useState(1);

	async function getPersonas(id_fraccionamiento, id_subfraccionamiento) {

		setLoading(true);

		const data = {
			id_fraccionamiento,
			id_subfraccionamiento
		}

		const response = await request.post('/admin/personas/get', data);

		if (response.personas) {
			setPersonas(response.personas);
		} else {
			cogoToast.error(response.message || 'Error al obtener personas.', { position: 'top-center'});
		}

		setLoading(false);
	}

	return (
		<div className="incidents column">
			<Helmet>
				<title>Appmosphera - Personas</title>
			</Helmet>
			<Header/>
			<div className="justify-center">
				<div className="container column">
					<div className="white-space-16" />
					<div className="navigation align-center">
						<div className="justify-start">
							<h3 className="weight-semi">Personas</h3>
						</div>
						<div className="justify-end">
							<p>
								<span className="weight-semi">Appmosphera</span> > Personas
							</p>
						</div>
					</div>
					<Controls
						newButton 	=	{false}
						setFilter	=	{(filter, fracc, subfrac) => getPersonas(fracc, subfrac)}/>
					<Tabla
						setPage		=	{(page) => setPage(page)}
						loading 	=	{loading}
						personas 	=	{personas}
						page 		=	{page}/>
				</div>
			</div>
		</div>
	)
}

export default Personas;