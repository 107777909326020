/**
 *  header.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Componente header
 */

import React, { Component } from "react";
import { Consumer } from "../../context";
import Request from "../../core/httpClient";
const request = new Request();

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 },
			denied: false
		};
	}

	render() {
		return (
			<header className="justify-center">
				<div className="container">
					<div className="justify-between">
						<nav className="align-center justify-end">
							{this.state.denied ? (
								<div className="row">
									<div className="nav-item auto">
										<button
											href="#one"
											className="font-regular color-white"
											onClick={this.handleLogout.bind(
												this
											)}>
											<i className="fas fa-sign-out-alt font-text" />
											Salir
										</button>
									</div>
								</div>
							) : (
									<div className="row">

										<div className="nav-item auto">
											<button
												href="#one"
												className="font-regular color-white"
												onClick={this.handleLogout.bind(
													this
												)}>
												<i className="fas fa-sign-out-alt font-text" />{" "}
												Salir
										</button>
										</div>
									</div>
								)}
						</nav>
					</div>
				</div>
			</header>
		);
	}

	componentDidMount() {
		this.load();
	}

	async load() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento
				}
			});
			this.getMenu();
		}
	}

	async getMenu() {
		this.setState({ loadingDiarios: true });
		let data = {
			idUsuario: this.state.user.idUsuario,
			idPerfil: this.state.user.idPerfil
		};
		const response = await request.post("/users/get/menu", data);
		if (response && !response.error) {
			if (response.menus && !response.denied) {
				this.setState({
					menus: response.menus,
					denied: false
				});
			} else {
				this.setState({
					denied: true
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
				denied: true
			});
		}
	}

	handleLogout() {
		this.props.context.logout();
		window.location = "/login";
	}
}

export default Consumer(Header);
