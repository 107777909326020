import React, { Component } from "react";
import ReactModal from "react-modal";
import Request from "../../../core/httpClient";
const request = new Request();

class ModalEditFracc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalEddit: false,
      eddit: false,
      idFracc: this.props.fraccionamiento.id_fraccionamiento,
      nombreFrac: this.props.fraccionamiento.nombre,
      descripcion: this.props.fraccionamiento.descripcion,
      direccion: this.props.fraccionamiento.direccion,
      municipio: this.props.fraccionamiento.municipio,
      estado: this.props.fraccionamiento.estado,
      pais: this.props.fraccionamiento.pais,
      contacto: this.props.fraccionamiento.contacto,
      telefono: this.props.fraccionamiento.telefono,
      email: this.props.fraccionamiento.correo,
      subfraccionamientos: [],
      activo: this.props.fraccionamiento.activo,
    };
  }

  render() {
    return (
      <div>
        <div className="inquilino-btns btn-container ">
          <button type="button" onClick={this.handleEddit.bind(this)}>
            <i className="fas fa-pen" /> Editar
          </button>
        </div>
        {
          <ReactModal
            isOpen={this.state.showModalEddit}
            className="modal modal-houses column"
            overlayClassName="overlay"
          >
            <div className="dinamic-header row justify-center">
              <div
                className="column modal-container justify-center"
                style={{ width: "90%" }}
              >
                <h3 className="title">Editar fracccionamiento</h3>
              </div>
              <button
                className="btn-modal-close-new justify-end"
                onClick={this.handleCloseEddit.bind(this)}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <form
              className="flex justify-center"
              onSubmit={this.handledSubmit.bind(this)}
            >
              <div className="container column">
                {/* <div className=" justify-start ">
                  <button
                    className="color-dark"
                    type="button"
                    onClick={this.handleEnable.bind(this)}
                  >
                    <i className="fas fa-edit" />
                    Clic para editar
                  </button>
                </div> */}
                <div className="white-space-16" />
                <div className="column">
                  <div className="input-form-content row full align-center">
                    <div className="column label-medium">
                      <p>
                        <b>Nombre:</b>
                      </p>
                    </div>
                    <div className="column full">
                      <input
                        type="text"
                        onChange={(event) =>
                          this.setState({ nombreFrac: event.target.value })
                        }
                        name="fullName"
                        className="input"
                        value={this.state.nombreFrac}
                        disabled={this.state.eddit}
                        //placeholder="Nombre del fracccionamiento"
                        required
                        minLength="4"
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="white-space-8" />
                  <div className="input-form-content row full align-center">
                    <div className="column label-medium">
                      <p>
                        <b>Descripcion:</b>
                      </p>
                    </div>
                    <div className="column full">
                      <input
                        type="text"
                        name="description"
                        onChange={(event) =>
                          this.setState({ descripcion: event.target.value })
                        }
                        className="input"
                        value={this.state.descripcion}
                        disabled={this.state.eddit}
                        //placeholder="Descripcion de fraccionamiento"
                        required
                        minLength="4"
                        maxLength="150"
                      />
                    </div>
                  </div>
                  <div className="white-space-24" />
                  <div className="input-form-content row full align-center">
                    <div className="column label-duo-big">
                      <p>
                        <b>Dirección:</b>
                      </p>
                    </div>
                    <div className="column full">
                      <input
                        type="text"
                        onChange={(event) =>
                          this.setState({ direccion: event.target.value })
                        }
                        name="address"
                        className="input"
                        disabled={this.state.eddit}
                        value={this.state.direccion}
                        // placeholder="Dirección de contacto"
                        required
                        minLength="6"
                        maxLength="150"
                      />
                    </div>
                    <div className="column label-duo-normal align-end">
                      <div className="column label-duo-big">
                        <p>
                          <b>Municipio:</b>
                        </p>
                      </div>
                    </div>
                    <div className="column full">
                      <input
                        type="text"
                        onChange={(event) =>
                          this.setState({ municipio: event.target.value })
                        }
                        name="municipality"
                        className="input"
                        disabled={this.state.eddit}
                        value={this.state.municipio}
                        // placeholder="Municipio"
                        required
                        minLength="4"
                        maxLength="80"
                      />
                    </div>
                  </div>
                  <div className="white-space-8" />
                  <div className="input-form-content row full align-center">
                    <div className="column label-duo-big">
                      <p>
                        <b>Pais:</b>
                      </p>
                    </div>
                    <div className="column full">
                      <input
                        type="text"
                        onChange={(event) =>
                          this.setState({ pais: event.target.value })
                        }
                        name="country"
                        className="input"
                        disabled={this.state.eddit}
                        value={this.state.pais}
                        // placeholder="Pais"
                        required
                        maxLength="20"
                      />
                    </div>
                    <div className="column label-duo-normal align-end">
                      <div className="column label-duo-big">
                        <p>
                          <b>Estado:</b>
                        </p>
                      </div>
                    </div>
                    <div className="column full">
                      <input
                        type="text"
                        name="state"
                        onChange={(event) =>
                          this.setState({ estado: event.target.value })
                        }
                        className="input"
                        disabled={this.state.eddit}
                        value={this.state.estado}
                        // placeholder="Estado"
                        required
                        minLength="4"
                        maxLength="80"
                      />
                    </div>
                  </div>
                  <div className="white-space-8" />
                  <div className="input-form-content row full align-center">
                    <div className="column label-medium">
                      <p>
                        <b>Contacto:</b>
                      </p>
                    </div>
                    <div className="column full">
                      <input
                        type="text"
                        name="contact"
                        onChange={(event) =>
                          this.setState({ contacto: event.target.value })
                        }
                        className="input"
                        disabled={this.state.eddit}
                        value={this.state.contacto}
                        //placeholder="contacto"
                        required
                      />
                    </div>
                  </div>
                  <div className="white-space-8" />
                  <div className="input-form-content row full align-center">
                    <div className="column label-duo-big">
                      <p>
                        <b>Teléfono:</b>
                      </p>
                    </div>
                    <div className="column full">
                      <input
                        type="text"
                        name="phone"
                        onChange={(event) =>
                          this.setState({ telefono: event.target.value })
                        }
                        className="input"
                        disabled={this.state.eddit}
                        value={this.state.telefono}
                        //placeholder="Teléfono"
                        required
                        maxLength="24"
                      />
                    </div>
                    <div className="column label-duo-normal align-end">
                      <div className="column label-duo-big">
                        <p>
                          <b>Email:</b>
                        </p>
                      </div>
                    </div>
                    <div className="column full">
                      <input
                        type="email"
                        name="email"
                        onChange={(event) =>
                          this.setState({ email: event.target.value })
                        }
                        className="input"
                        disabled={this.state.eddit}
                        value={this.state.email}
                        // placeholder="email"
                        required
                      />
                    </div>
                  </div>
                  <div className="white-space-32" />
                  <div className="input-form-content row full align-center justify-start">
                    <div className="column">
                      <label className="container-check">
                        <input
                          type="checkbox"
                          id="activar"
                          checked={this.state.activo === 1 ? true : false}
                          name="activo"
                          onChange={(e) =>
                            this.setState({
                              activo: this.state.activo === 1 ? 0 : 1,
                            })
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="column">
                      <h5>
                        <b>Activar fraccionamiento</b>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="white-space-24" />
                {!this.state.eddit ? (
                  <div className="row justify-end">
                    <button
                      type="button"
                      className="btn-action-cancel"
                      onClick={this.handleCloseEddit.bind(this)}
                    >
                      <i className="fas fa-times" />
                      &nbsp; CANCELAR
                    </button>
                    <button type="submit" className="btn-action-success">
                      <i className="fas fa-sync-alt" />
                      &nbsp; ACTUALIZAR
                    </button>
                  </div>
                ) : null}

                {/*  <div className="white-space-16" />
                <div>
                  <h3>SubFraccionamientos asociados</h3>
                </div>
                <div className="">
                  {this.state.subfraccionamientos.map(
                    (fraccionamiento, key) => (
                      <p className="" key={key}>
                        {key + 1}- {fraccionamiento.nombre}- DIR:
                        {fraccionamiento.direccion}-{"       TEL: "}
                        {fraccionamiento.telefono}
                      </p>
                    )
                  )}
                </div> */}

                <div className="white-space-24" />
              </div>
            </form>
          </ReactModal>
        }
      </div>
    );
  }

  // traer datos fraccionamiento
  async getFraccionamiento() {
    this.getSubFraccionamiento();

    let data = { IdFraccionamiento: this.state.idFracc };
    const response = await request.post(
      "/admin/administracion/get/fraccionamientos/all",
      data
    );

    if (response && !response.error) {
      if (response.fraccionamiento && !response.empty) {
        this.setState({
          nombreFrac: response.fraccionamiento[0].nombre,
          descripcion: response.fraccionamiento[0].descripcion,
          direccion: response.fraccionamiento[0].direccion,
          municipio: response.fraccionamiento[0].municipio,
          estado: response.fraccionamiento[0].estado,
          pais: response.fraccionamiento[0].pais,
          contacto: response.fraccionamiento[0].contacto,
          telefono: response.fraccionamiento[0].telefono,
          email: response.fraccionamiento[0].correo,
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
      });
    }
  }
  // traer subfraccionamiento
  async getSubFraccionamiento() {
    let data = { Idsub: this.state.idFracc };

    const response = await request.post(
      "/admin/administracion/get/subfraccionamiento",
      data
    );

    if (response && !response.error) {
      if (response.subfraccionamiento && !response.empty) {
        this.setState({
          subfraccionamientos: response.subfraccionamiento,
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
      });
    }
  }
  // GUARDA FORMULARIO
  async handledSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const form = event.target;

    let data = {
      id_fraccionamiento: this.state.idFracc,
      Nombre: form.fullName.value,
      Descripcion: form.description.value,
      Direccion: form.address.value,
      Municipio: form.municipality.value,
      Estado: form.state.value,
      Pais: form.country.value,
      Contacto: form.contact.value,
      Telefono: form.phone.value,
      Email: form.email.value,
      activo: this.state.activo,
    };
    this.setState({
      showModalEddit: false,
    });
    this.props.eddit(data);
  }
  handleOpen() {
    this.setState({ showModal: true });
  }
  handleClose() {
    this.setState({ showModal: false });
  }
  handleCloseEddit() {
    this.setState({ showModalEddit: false });
  }
  handleEddit() {
    this.setState({ showModalEddit: true });
    this.getFraccionamiento();
  }
  handleEnable() {
    this.setState({
      eddit: !this.state.eddit,
    });
  }
}

export default ModalEditFracc;
