import Request from '../core/httpClient';

const req = new Request();

export async function loadSubFraccionamientos() {
    const res = await req.post('/admin/subfraccionamientos/get', {});
    if (res.subfraccionamientos) {
        this.setState({subfraccionamientos: res.subfraccionamientos});
    } else {
        this.setState({subfraccionamientos: []});
    }
}