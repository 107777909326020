import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import ReactModal from "react-modal";
import Request from "../core/httpClient";
import Header from "../components/header/header";
import AdminTable from "../components/adminIgate/administracion/admintable";
import HousesTable from "../components/services/services-table";
import cogoToast from "cogo-toast";
const request = new Request();

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalAddAdmin: false,
      loading: false,
      administraciones: [],
      user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 },
      loadingAdministraciones: false,
    };
  }

  render() {
    return (
      <div className="houses column">
        <Helmet>
          <title>AppMosphera - Admin</title>
        </Helmet>
        <Header />
        <div className="justify-center">
          <div className="container column">
            <div className="white-space-16" />

            <div className="navigation align-center">
              <div className="justify-start">
                <h3 className="weight-semi">Administraciones</h3>
              </div>
              <div className="justify-end">
                <button
                  type="button"
                  className="btn btn-primary btn-small color-white"
                  onClick={this.handleOpen.bind(this)}
                >
                  <i className="fas fa-plus font-text" />
                  &nbsp; Añadir Administración
                </button>
              </div>
            </div>
            <div className="white-space-16" />
            <AdminTable
              dataAdmin={this.state.administraciones}
              loadingAdministraciones={this.state.loadingAdministraciones}
            />
          </div>
        </div>

        {/* modal agregar administracion */}
        <ReactModal
          isOpen={this.state.showModalAddAdmin}
          className="modal modal-houses column"
          overlayClassName="overlay"
          style={{ padding: 0 }}
        >
          <div class="dinamic-header row justify-center">
            <div
              class="column modal-container justify-center"
              style={{ width: "90%" }}
            >
              <h3 class="title">Nueva Administración</h3>
            </div>
            <button
              class="btn-modal-close-new justify-end"
              onClick={this.handleClose.bind(this)}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <form
            className="flex justify-center"
            onSubmit={this.handledSubmit.bind(this)}
          >
            <div class="white-space-16"></div>
            <div className="container column">
              <div className="column">
                <div className="white-space-24" />
                <h5 className="weight-semi">Datos de la empresa</h5>
                <div className="white-space-8" />
                <div className="input-form-content row full align-center">
                  <div className="column label-medium">
                    <p>
                      <b>Nombre:</b>
                    </p>
                  </div>
                  <div className="column full">
                    <input
                      type="text"
                      name="companyName"
                      className="input"
                      // placeholder="Nombre completo"
                      required
                    />
                  </div>
                </div>
                <div className="white-space-8" />
                <div className="input-form-content row full align-center">
                  <div className="column label-duo-big">
                    <p>
                      <b>Dirección:</b>
                    </p>
                  </div>
                  <div className="column full">
                    <input
                      type="text"
                      name="companyAddress"
                      className="input"
                      // placeholder="Dirección de contacto"
                      required
                    />
                  </div>
                  <div className="column label-duo-normal align-end">
                    <div className="column label-duo-big">
                      <p>
                        <b>Municipio:</b>
                      </p>
                    </div>
                  </div>
                  <div className="column full">
                    <input
                      type="text"
                      name="municipality"
                      className="input"
                      // placeholder="Municipio"
                      required
                    />
                  </div>
                </div>
                <div className="white-space-8" />
                <div className="input-form-content row full align-center">
                  <div className="column label-duo-big">
                    <p>
                      <b>País:</b>
                    </p>
                  </div>
                  <div className="column full">
                    <input
                      type="text"
                      name="country"
                      className="input"
                      // placeholder="pais"
                      required
                    />
                  </div>
                  <div className="column label-duo-normal align-end">
                    <div className="column label-duo-big">
                      <p>
                        <b>Estado:</b>
                      </p>
                    </div>
                  </div>
                  <div className="column full">
                    <input
                      type="text"
                      name="state"
                      className="input"
                      // placeholder="Estado"
                      required
                    />
                  </div>
                </div>
                <div className="white-space-32" />
                <h5 className="weight-semi">Datos de contacto</h5>
                <div className="white-space-8" />
                <div className="input-form-content row full align-center">
                  <div className="column label-medium">
                    <p>
                      <b>Nombre:</b>
                    </p>
                  </div>
                  <div className="column full">
                    <input
                      type="text"
                      name="contact"
                      className="input"
                      //placeholder="Nombre contacto"
                      required
                      maxLength="24"
                    />
                  </div>
                </div>
                <div className="input-form-content row full align-center">
                  <div className="column label-duo-big">
                    <p>
                      <b>Teléfono:</b>
                    </p>
                  </div>
                  <div className="column full">
                    <input
                      type="text"
                      name="phone"
                      className="input"
                      // placeholder="Teléfono"
                      required
                      maxLength="24"
                    />
                  </div>
                  <div className="column label-duo-normal align-end">
                    <div className="column label-duo-big">
                      <p>
                        <b>Email:</b>
                      </p>
                    </div>
                  </div>
                  <div className="column full">
                    <input
                      type="email"
                      name="email"
                      className="input"
                      // placeholder="email"
                      required
                      maxLength="24"
                    />
                  </div>
                </div>
              </div>
              <div className="white-space-32" />
              <div className="dinamic-footer row justify-center">
                <div className="row justify-end">
                  <button
                    type="button"
                    className="btn-action-cancel"
                    onClick={this.handleClose.bind(this)}
                  >
                    <i className="fas fa-times" /> &nbsp; CANCELAR
                  </button>
                  <button type="submit" className="btn-action-success">
                    {this.state.loading ? (
                      <i className="fas fa-spinner fa-spin" />
                    ) : (
                      <span>
                        {" "}
                        <i className="fas fa-check" /> &nbsp; REGISTRAR
                      </span>
                    )}
                  </button>
                </div>
                <div class="white-space-16"></div>
              </div>
            </div>
          </form>
        </ReactModal>

        <div className="justify-center">
          <div className="container column">
            <div className="white-space-16" />

            <div className="navigation align-center">
              <div className="justify-end">
                <button
                  type="button"
                  className="btn btn-primary btn-small color-white"
                  onClick={this.handleOpen.bind(this)}
                >
                  <i className="fas fa-plus font-text" />
                  &nbsp; Agregar Proveedor de Servicio
                </button>
              </div>
            </div>
            <div className="white-space-16" />
            <HousesTable dataAdmin={this.state} />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.load();
  }
  async load() {
    const user = await this.props.context.loadUser();
    if (user) {
      this.setState({
        user: {
          idUsuario: user.id.id_usuario,
          idPerfil: user.id.id_perfil,
          idFraccionamiento: user.id.id_fraccionamiento,
        },
      });
    }

    this.getAdministraciones();
  }
  // ABRE MODAL
  handleOpen() {
    this.setState({ showModalAddAdmin: true });
  }
  // GUARDA FORMULARIO
  async handledSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const form = event.target;

    let data = {
      Nombre: form.companyName.value,
      Direccion: form.companyAddress.value,
      Municipio: form.municipality.value,
      Estado: form.state.value,
      Pais: form.country.value,
      NombreContacto: form.contact.value,
      Telefono: form.phone.value,
      email: form.email.value,
    };

    const response = await request.post("/admin/administracion/create", data);

    this.setState({ showModalAddAdmin: false });
    if (response && !response.error) {
      if (response.created && !response.empty) {
        this.setState({
          loading: false,
        });
        cogoToast.success("Administración agregada.", {
          position: "bottom-right",
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
          loading: false,
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
        loading: false,
      });
      cogoToast.error("No se pudo agregar la nueva administración.", {
        position: "bottom-right",
      });
    }
    this.getAdministraciones();
  }
  // CIERRA MODAL
  handleClose() {
    this.setState({ showModalAddAdmin: false });
  }
  // // TRAER TODAS LAS ADMINISTRACIONES
  async getAdministraciones() {
    this.setState({ loadingAdministraciones: true });
    let data = {
      idPerfil: this.state.user.idPerfil,
    };

    const response = await request.post("/admin/administracion/get/all", data);

    if (response && !response.error) {
      if (response.administraciones && !response.empty) {
        this.setState({
          administraciones: response.administraciones,
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
      });
    }
    this.setState({ loadingAdministraciones: false });
  }
}

export default Consumer(Admin);
