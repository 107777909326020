import Request from '../core/httpClient';

const req = new Request();

export async function loadViviendas() {
    const res = await req.post('/admin/viviendas/get', {});
    console.log(res);
    if (res.viviendas) {
        this.setState({viviendas: res.viviendas});
    } else {
        this.setState({viviendas: []});
    }
}