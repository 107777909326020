/**
 *  urlApi.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Manejor de las ruta directas al API
 */

function url() {
  let url = "http://localhost:8081";
  //let url = "http://192.168.1.74:8081";

  if (process.env.NODE_ENV === "production") {
    url = "https://igateapi.herokuapp.com";
  }
  return url;
}

export const URL_API = url();
