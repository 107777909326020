/**
 *  incidents.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de Inicio de Incidencias
 */

import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Controls from "../components/controls/controls";
import NotificationsTable from "../components/notifications/table";
import DinamicModal from "../components/dinamicModal/dinamicModal";
import NotificacionForm from "../components/notifications/form";
import Request from "../core/httpClient";
import cogoToast from "cogo-toast";
import moment from "moment";

const request = new Request();

class Incidents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fraccionamientos: [],
      notificaciones: [],
      page: 1,
      formValues: {},
      filter: "",
      fraccFilter: 0,
    };
  }

  componentDidMount() {
    this.loadNotificaciones();
  }

  async loadNotificaciones() {
    this.setState({ loading: true });
    const response = await request.post("/admin/notificaciones/get");
    if (response.notificaciones) {
      this.setState({ notificaciones: response.notificaciones });
    } else {
      this.setState({ notificaciones: [] });
    }
    this.setState({ loading: false });
  }

  async openModal(idModal) {
    const modal = document.getElementById(idModal);
    if (modal) {
      modal.classList.remove("hide-dinamic-modal");
      modal.classList.add("show-dinamic-modal");
    }
    this.setState({ isModalOpen: true });
  }

  closeModal(idModal) {
    const modal = document.getElementById(idModal);
    if (modal) {
      modal.classList.add("hide-dinamic-modal");
      modal.classList.remove("show-dinamic-modal");
    }
    this.setState({ isModalOpen: false });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ loadingSave: true });
    const data = this.state.formValues;
    data.fecha = moment().format();
    const response = await request.post("/admin/notificaciones/send", data);
    if (!response || response.error) {
      cogoToast.error("No fue posible enviar las notificación.", {
        position: "bottom-right",
      });
    } else if (response.created) {
      cogoToast.success("Notificación enviada.", {
        position: "bottom-right",
      });
      this.closeModal("modal-nueva-notificacion");
      this.loadNotificaciones();
    }
    this.setState({ loadingSave: false });
  }

  setFilter(filter) {
    this.setState({ filter: filter.toLowerCase() });
  }
  fracChange(fraccFilter) {
    this.setState({ fraccFilter: fraccFilter });
  }

  filter(notificaciones) {
    notificaciones = notificaciones.filter((noti) => {
      if (
        noti.titulo.toLowerCase().indexOf(this.state.filter) !== -1 &&
        (this.state.fraccFilter == 0 ||
          noti.id_fraccionamiento == this.state.fraccFilter)
      ) {
        return true;
      } else {
        return false;
      }
    });
    return notificaciones;
  }

  render() {
    let notificaciones = [];

    if (Array.isArray(this.state.notificaciones)) {
      notificaciones = this.state.notificaciones;
      // //console.log(dataSet);
      notificaciones = this.filter(notificaciones);
    }

    return (
      <div className="incidents column">
        <Helmet>
          <title>Appmosphera - Notificaciones</title>
        </Helmet>
        <Header />
        <div className="justify-center">
          <div className="container column">
            <div className="white-space-16" />
            <div className="navigation align-center">
              <div className="justify-start">
                <h3 className="weight-semi">Notificaciones</h3>
              </div>
              {/* <div className="justify-end">
                <p>
                  <span className="weight-semi">Appmosphera</span> >
                  Notificaciones
                </p>
              </div> */}
            </div>
            <Controls
              newButton={true}
              setFilter={this.setFilter.bind(this)}
              fracChange={this.fracChange.bind(this)}
              newEvent={this.openModal.bind(this, "modal-nueva-notificacion")}
            />
            <NotificationsTable
              loading={this.state.loading}
              notificaciones={notificaciones}
              page={this.state.page}
            />
            <DinamicModal
              idModal={"modal-nueva-notificacion"}
              isOpen={this.state.showModal}
              className="modal modal-incidents column"
              overlayClassName="overlay"
              title="Nueva Notificación"
              showBtnSuccess={true}
              btnTextSuccess="ENVIAR"
              showBtnCancel={true}
              btnTextCancel="CANCELAR"
              success={this.handleSubmit.bind(this)}
              loadingSave={this.state.loadingSave}
              closeModal={() => this.setState({ showModal: false })}
            >
              <NotificacionForm
                isModalOpen={this.state.showModal}
                formValues={(formValues) => this.setState({ formValues })}
              />
            </DinamicModal>
          </div>
        </div>
      </div>
    );
  }
}

export default Consumer(Incidents);
