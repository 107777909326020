import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Consumer } from "../../context";
import Logo from "../../img/logo.png";

const Navbar = (props) => {
  const { context } = props;

  if (!context.user) {
    return null;
  }

  return (
    <nav className="sidebar column align-center">
      <div className="logo align-center">
        <Link to="/admin" className="responsive-img auto">
          <img src={Logo} alt="Logo Appmosphera" title="Logo Appmosphera" />
        </Link>
        <p className="font-large weight-medium color-white">
          &nbsp;Appmosphera
        </p>
      </div>
      <div className="row justify-start">
        <NavLink className="nav-item" to="/admin">
          Inicio
        </NavLink>
      </div>
      <div className="row justify-start">
        <NavLink className="nav-item" to="/notificaciones">
          Notificaciones
        </NavLink>
      </div>
      {/* <div className="row justify-start">
        <NavLink className="nav-item" to="/personas">
          Personas
        </NavLink>
      </div> */}
    </nav>
  );
};

export default Consumer(Navbar);
