/**
 *  services-table.jsx
 *  @version: 1.0.0
 *  @author:
 *  @description: Tablas de SERVICIOS PROVEEDOR
 */

import React, { Component } from "react";
import { Consumer } from "../../context";
import ReactModal from "react-modal";
import DatePicker from "react-datepicker";
import Request from "../../core/httpClient";
import moment from "moment";
const request = new Request();

class servicesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      edit: false,
      add: false,
      edditInq: false,
      edditProp: false,
      startDate: new Date(),
      propietarios: [],

      pDireccion: "",
      pNregistro: "",
      pNombre: "",
      pCp: "",
      pEstado: "",
      pMunicipio: "",
      pTelefono: "",
      pVdireccion: "",
      pIdProp: "",
      pIdViv: "",
      inquilinos: [],

      iNombre: "",
      iTelefono: "",
      ifechaNac: "",
      isexo: "",
      ipassword: "",
      iIdInq: "",
      inquilinoId: 0,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  render() {
    let services = [];

    if (this.props.dataServices) {
      services = this.props.dataServices;
    }

    return (
      <div className="card-table card-owners column" id="card-owners">
        <div className="title">
          <h3>Servicios</h3>
        </div>
        <div className="table-responsive">
          <table>
            <thead>
              <tr className="text-left">
                <th className="proveedor">Proveedor</th>
                <th className="services">Servicio</th>
                <th className="phone">Teléfono</th>
                <th className="phone">WhatsApp</th>
                <th className="name">Web</th>
                <th className="city">Ciudad</th>
                <th className="phone">Editar</th>
              </tr>
            </thead>
            <tbody>
              {services.map((services, key) => (
                <tr key={key}>
                  <td>{services.proveedor}</td>
                  <td>{services.servicio}</td>
                  <td>{services.telefono}</td>
                  <td>{services.whatsapp}</td>
                  <td>{services.web}</td>
                  <td>{services.city}</td>
                  <td
                    className="text-center weight-semi cursor-pointer"
                    onClick={this.handleOpen.bind(this, services.proveedor)}
                  >
                    editar
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ReactModal
          isOpen={this.state.showModal}
          className="modal modal-houses column"
          overlayClassName="overlay"
        >
          <form
            onSubmit={this.handledSubmit.bind(this)}
            className="flex justify-center"
          >
            <div className="container column">
              <div className="close-modal justify-end">
                <button
                  className="btn-modal-close color-dark"
                  onClick={this.handleClose.bind(this)}
                >
                  <i className="fas fa-times" />
                </button>
              </div>
              <div className="align-center justify-between">
                <h3>Datos de la vivienda</h3>
                <button
                  type="button"
                  id="btn-modal-edit"
                  onClick={this.handleEdit.bind(this)}
                >
                  &nbsp; <i className="font-text fas fa-edit" /> Click para
                  editar
                </button>
              </div>
              <div className="white-space-8" />
              <div className="align-center">
                <p>
                  <b>Dirección:</b>
                </p>
                <input
                  type="text"
                  name="houseAddress"
                  className="input"
                  placeholder="Dirección completa"
                  required
                  maxLength="150"
                  disabled={!this.state.edit}
                  value={this.state.pVdireccion}
                  onChange={(event) =>
                    this.setState({ pVdireccion: event.target.value })
                  }
                />
              </div>
              <div className="white-space-8" />
              <div className="align-center">
                <p>
                  <b>Número/Letra:</b>
                </p>
                <input
                  type="text"
                  name="houseNumber"
                  className="input"
                  placeholder="N°/Letra de vivienda"
                  required
                  maxLength="8"
                  disabled={!this.state.edit}
                  value={this.state.pNregistro}
                  onChange={(event) =>
                    this.setState({
                      pNregistro: event.target.value,
                    })
                  }
                />
              </div>
              <div className="white-space-8" />
              <div className="align-center">
                <p>
                  <b>Fraccionamiento:</b>
                </p>
                <input
                  type="text"
                  name="fraccionamiento"
                  className="input"
                  placeholder="Fraccionamiento"
                  readOnly
                  disabled
                />
              </div>

              {/* seccion de propietarios */}
              <div className="column">
                <div className="white-space-24" />
                <div className="align-center justify-between">
                  <h4>Propietarios</h4>
                </div>

                <div>
                  {this.state.propietarios.map((propietario, key) => (
                    <div className="justify-between" key={key}>
                      <p className="inquilino-name">
                        {key + 1}- {propietario.nombre} - {propietario.telefono}{" "}
                        -{" "}
                      </p>
                      <div className="inquilino-btns btn-container align-center">
                        <button
                          type="button"
                          onClick={this.handleEditProp.bind(
                            this,
                            propietario.id_propietario
                          )}
                        >
                          <i className="fas fa-pen" /> Editar
                        </button>
                        <button type="button">
                          <i className="fas fa-times" /> Eliminar
                        </button>
                      </div>
                    </div>
                  ))}
                </div>

                {/* FORMULARIO PARA EDITAR EL PROPIETARIO */}
                {this.state.edditProp ? (
                  <div>
                    <div className="white-space-32" />
                    <p>
                      <b>Editar informacion de inquilino:</b>
                    </p>
                    <div className="align-center">
                      <p>
                        {" "}
                        <b>Nombre propietario:</b>
                      </p>
                      <input
                        type="text"
                        name="fullName"
                        id="fullNameEdit"
                        className="input"
                        placeholder="Nombre completo"
                        required
                        minLength="6"
                        maxLength="64"
                        value={this.state.pNombre}
                        onChange={(event) =>
                          this.setState({ pNombre: event.target.value })
                        }
                      />
                    </div>
                    <div className="align-center">
                      <p>
                        {" "}
                        <b>Dirección:</b>{" "}
                      </p>
                      <input
                        type="text"
                        name="address"
                        id="addressEdit"
                        className="input"
                        placeholder="Dirección de contacto"
                        required
                        minLength="6"
                        maxLength="150"
                        value={this.state.pDireccion}
                        onChange={(event) =>
                          this.setState({ pDireccion: event.target.value })
                        }
                      />
                    </div>
                    <div className="white-space-8" />
                    <div className="align-center">
                      <p>
                        {" "}
                        <b>Municipio:</b>{" "}
                      </p>
                      <input
                        type="text"
                        name="municipality"
                        id="municipalityEdit"
                        className="input"
                        placeholder="Municipio"
                        required
                        minLength="6"
                        maxLength="80"
                        value={this.state.pMunicipio}
                        onChange={(event) =>
                          this.setState({ pMunicipio: event.target.value })
                        }
                      />
                    </div>
                    <div className="white-space-8" />
                    <div className="align-center">
                      <p>
                        <b>Estado:</b>
                      </p>
                      <input
                        type="text"
                        name="state"
                        id="stateEdit"
                        className="input"
                        placeholder="Estado"
                        required
                        minLength="6"
                        maxLength="80"
                        value={this.state.pEstado}
                        onChange={(event) =>
                          this.setState({ pEstado: event.target.value })
                        }
                      />
                    </div>
                    <div className="white-space-8" />
                    <div className="align-center">
                      <p>
                        <b>C.P:</b>
                      </p>
                      <input
                        type="text"
                        name="zip"
                        id="zipEDit"
                        className="input"
                        placeholder="Código postal"
                        required
                        maxLength="10"
                        value={this.state.pCp}
                        onChange={(event) =>
                          this.setState({ pCp: event.target.value })
                        }
                      />
                    </div>
                    <div className="white-space-8" />
                    <div className="align-center">
                      <p>
                        <b>Teléfono:</b>
                      </p>
                      <input
                        type="text"
                        name="phone"
                        id="phoneEdit"
                        className="input"
                        placeholder="Teléfono"
                        required
                        maxLength="24"
                        value={this.state.pTelefono}
                        onChange={(event) =>
                          this.setState({ pTelefono: event.target.value })
                        }
                      />
                    </div>
                    <div className="white-space-24" />
                    <div className="btn-container">
                      <button
                        type="button"
                        className="btn btn-primary color-white"
                        onClick={this.editarPropietarios.bind(
                          this,
                          this.state.pIdProp,
                          this.state.pIdViv
                        )}
                      >
                        <i className="fas fa-check" />
                        &nbsp; GUARDAR
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary color-white"
                        onClick={this.handleEditProp.bind(this)}
                      >
                        <i className="fas fa-times" />
                        &nbsp; CANCELAR
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
              {/* termina seccion de propietarios */}

              {/* lista de inquilinos */}
              <div className="white-space-24" />
              <div className="white-space-24" />
              <div className="align-center justify-between">
                <h4>Inquilinos</h4>
                <button
                  type="button"
                  id="btn-modal-inqulino-add"
                  onClick={this.handleAdd.bind(this, this.state.pIdViv)}
                >
                  &nbsp; <i className="font-text fas fa-plus" /> Añadir inqulino
                </button>
              </div>
              <div className="white-space-24" />
              <div>
                {this.state.inquilinos.map((inquilino, key) => (
                  <div className="justify-between" key={key}>
                    <p className="inquilino-name">
                      {key + 1}- {inquilino.nombre} - {inquilino.telefono} -{" "}
                      {inquilino.sexo === 0 ? "Hombre" : "Mujer"}
                    </p>
                    <div className="inquilino-btns btn-container align-center">
                      <button
                        type="button"
                        onClick={this.handleEditInq.bind(
                          this,
                          inquilino.id_inquilino
                        )}
                      >
                        <i className="fas fa-pen" /> Editar
                      </button>

                      <button
                        type="button"
                        onClick={this.deleteInquilino.bind(
                          this,
                          inquilino.id_inquilino,
                          this.state.pIdViv
                        )}
                      >
                        <i className="fas fa-times" /> Eliminar
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              {/* EDITAR LOS INQUILINOS */}
              {this.state.edditInq ? (
                <div className="column">
                  <div className="white-space-32" />
                  <p>
                    <b>Editar informacion de inquilino:</b>
                  </p>
                  <div className="white-space-16" />
                  <div className="align-center">
                    <p>
                      <b>Nombre:</b>
                    </p>
                    <input
                      type="text"
                      name="nameInquilinoEdit"
                      value={this.state.iNombre}
                      onChange={(event) =>
                        this.setState({ iNombre: event.target.value })
                      }
                      id="nameInquilinoEdit"
                      className="input"
                      placeholder="Nombre completo"
                      required
                    />
                  </div>
                  <div className="white-space-24" />
                  <div className="align-center">
                    <div className="white-space-24" />
                    <p>
                      <b>Teléfono:</b>
                    </p>
                    <input
                      type="number"
                      name="phoneInquilinoEdit"
                      id="phoneInquilinoEdit"
                      value={this.state.iTelefono}
                      onChange={(event) =>
                        this.setState({ iTelefono: event.target.value })
                      }
                      className="input"
                      placeholder="Número telefónico"
                      required
                    />
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Fecha de nacimiento:</b>
                    </p>
                    <DatePicker
                      className="input"
                      dateFormat="yyyy-MM-d"
                      selected={this.state.startDate}
                      onChange={this.handleDateChange}
                    />
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Sexo:</b>
                    </p>
                    <select
                      id="sexEdit"
                      name="gender"
                      className="input input-select"
                      value={this.state.isexo}
                      onChange={(event) =>
                        this.setState({ isexo: event.target.value })
                      }
                    >
                      <option value="0">Hombre</option>
                      <option value="1">Mujer</option>
                    </select>
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Contraseña:</b>
                    </p>
                    <input
                      type="password"
                      name="passwordEdit"
                      id="passwordEdit"
                      value={this.state.ipassword}
                      onChange={(event) =>
                        this.setState({ ipassword: event.target.value })
                      }
                      className="input"
                      placeholder="Contraseña"
                      minLength="8"
                      maxLength="24"
                      required
                    />
                  </div>
                  <div className="white-space-24" />
                  <div className="align-left">
                    <button
                      type="button"
                      onClick={this.editarInquilino.bind(
                        this,
                        this.state.pIdViv
                      )}
                      className="btn btn-primary color-white"
                    >
                      <i className="fas fa-check" />
                      &nbsp; Guardar cambios
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary color-white"
                      onClick={this.handleEditInq.bind(this)}
                    >
                      <i className="fas fa-times" />
                      &nbsp; CANCELAR
                    </button>
                  </div>
                  <div className="white-space-24" />
                </div>
              ) : null}
              {/* agregar inquilino */}
              {this.state.add ? (
                <div className="column">
                  <div className="white-space-16" />
                  <div className="align-center">
                    <p>
                      <b>Nombre:</b>
                    </p>
                    <input
                      type="text"
                      name="nameInquilino"
                      id="nombreInq"
                      className="input"
                      placeholder="Nombre completo"
                      required
                    />
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Teléfono:</b>
                    </p>
                    <input
                      type="number"
                      name="phoneInquilino"
                      id="telefonoInq"
                      className="input"
                      placeholder="Número telefónico"
                      required
                    />
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Fecha de nacimiento:</b>
                    </p>
                    <DatePicker
                      className="input"
                      dateFormat="yyyy-MM-d"
                      selected={this.state.startDate}
                      onChange={this.handleDateChange}
                    />
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Sexo:</b>
                    </p>
                    <select
                      id="sex"
                      name="gender"
                      className="input input-select"
                    >
                      <option value="0">Hombre</option>
                      <option value="1">Mujer</option>
                    </select>
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Contraseña:</b>
                    </p>
                    <input
                      type="password"
                      name="password"
                      id="passInq"
                      className="input"
                      placeholder="Contraseña"
                      minLength="8"
                      maxLength="24"
                      required
                    />
                  </div>
                  <div className="white-space-24" />
                  <div className="align-left">
                    <button
                      type="button"
                      onClick={this.guardarInquilino.bind(
                        this,
                        this.state.pIdViv
                      )}
                      className="btn btn-primary color-white"
                    >
                      <i className="fas fa-check" />
                      &nbsp; Agregar Inquilino nuevo
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary color-white"
                      onClick={this.handleAdd.bind(this)}
                    >
                      <i className="fas fa-times" />
                      &nbsp; CANCELAR
                    </button>
                  </div>
                  <div className="white-space-24" />
                </div>
              ) : null}
              <div className="white-space-24" />

              {/* botones para enviar el formulario */}
              <div className="white-space-32" />
              <div className="btn-container">
                <button
                  type="submit"
                  className="btn btn-primary color-white"
                  disabled={!this.state.edit}
                >
                  <i className="fas fa-check" />
                  &nbsp; ACTUALIZAR DATOS
                </button>
                <button
                  type="button"
                  className="btn btn-secondary color-white"
                  onClick={this.handleClose.bind(this)}
                >
                  <i className="fas fa-times" />
                  &nbsp; CANCELAR
                </button>
              </div>
            </div>
          </form>
        </ReactModal>
      </div>
    );
  }

  // ENVIA LOS DATOS DEL FORMULARIO DE DATOS DE VIVIENDA SIN LOS INQUILINOS
  async handledSubmit(event) {
    event.preventDefault();
    const form = event.target;
    let data = {
      IdVivienda: this.state.pIdViv,
      Direccion: form.houseAddress.value,
      Numero_Registro: form.houseNumber.value,
    };
    const response = await request.post("/viviendas/vivienda/update", data);

    if (response && !response.error) {
      if (response.updated && !response.empty) {
        this.setState({
          reload: true,
          loading: false,
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
          loading: false,
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
        loading: false,
      });
    }
    this.setState({ showModal: false });
  }
  // GUARDA LOS DATOS DEL INQUILINO
  async guardarInquilino(idVivienda) {
    let validar = document.getElementById("nombreInq").value,
      Telefono = document.getElementById("telefonoInq").value,
      pass = document.getElementById("passInq").value,
      Sexo = document.getElementById("sex").value;
    if (
      validar.length === 0 ||
      Telefono.length === 0 ||
      Sexo.length === 0 ||
      pass.length === 0
    ) {
      return;
    } else {
      let data = {
        IdVivienda: idVivienda,
        Nombre: document.getElementById("nombreInq").value,
        Telefono: document.getElementById("telefonoInq").value,
        password: document.getElementById("passInq").value,
        FechaNac: moment(this.state.startDate).format("YYYY-MM-DD"),
        Sexo: document.getElementById("sex").value,
      };

      const response = await request.post("/viviendas/inquilino/crear", data);
      if (response && !response.error) {
        if (response.created && !response.empty) {
          this.setState({
            reload: true,
            loading: false,
          });
          this.getInquilinoVivienda(idVivienda);
          this.limpiarValores();
        } else {
          this.setState({
            empty: true,
            message: response.message,
            loading: false,
          });
        }
      } else {
        this.setState({
          error: true,
          message: response.message,
          loading: false,
        });
      }
    }
  }
  // EDITAR LOS DATOS DEL INQUILINO
  async editarInquilino(idVivienda) {
    let validar = document.getElementById("nameInquilinoEdit").value,
      Telefono = document.getElementById("phoneInquilinoEdit").value,
      pass = document.getElementById("passwordEdit").value,
      Sexo = document.getElementById("sexEdit").value;
    if (
      validar.length === 0 ||
      Telefono.length === 0 ||
      Sexo.length === 0 ||
      pass.length === 0
    ) {
      return;
    } else {
      let data = {
        IdInquilino: this.state.inquilinoId,
        Nombre: document.getElementById("nameInquilinoEdit").value,
        Telefono: document.getElementById("phoneInquilinoEdit").value,
        password: document.getElementById("passwordEdit").value,
        FechaNac: moment(this.state.startDate).format("YYYY-MM-DD"),
        Sexo: document.getElementById("sexEdit").value,
      };

      const response = await request.post("/viviendas/inquilino/update", data);

      if (response && !response.error) {
        if (response.created && !response.empty) {
          this.setState({
            reload: true,
            loading: false,
            edditInq: false,
          });

          this.getInquilinoVivienda(idVivienda);
        } else {
          this.setState({
            empty: true,
            message: response.message,
            loading: false,
          });
        }
      } else {
        this.setState({
          error: true,
          message: response.message,
          loading: false,
        });
      }
    }
  }
  //  EDITAR LOS DATOS DEL PROPIETARIO
  async editarPropietarios(idPropietario, id_vivienda) {
    let nombreProp = document.getElementById("fullNameEdit").value,
      direccionProp = document.getElementById("addressEdit").value,
      municipioProp = document.getElementById("municipalityEdit").value,
      estadoProp = document.getElementById("stateEdit").value;

    if (
      nombreProp.length === 0 ||
      direccionProp.length === 0 ||
      municipioProp.length === 0 ||
      estadoProp.length === 0
    ) {
      return;
    } else {
      let data = {
        IdPropietario: idPropietario,
        Nombre: document.getElementById("fullNameEdit").value,
        Direccion: document.getElementById("addressEdit").value,
        Municipio: document.getElementById("municipalityEdit").value,
        Cp: document.getElementById("zipEDit").value,
        Estado: document.getElementById("stateEdit").value,
        Telefono: document.getElementById("phoneEdit").value,
      };
      const response = await request.post("/propietarios/update", data);

      if (response && !response.error) {
        if (response.updated && !response.empty) {
          this.setState({
            reload: true,
            loading: false,
            edditProp: false,
          });

          this.getPropietariosVivienda(id_vivienda);
        } else {
          this.setState({
            empty: true,
            message: response.message,
            loading: false,
          });
        }
      } else {
        this.setState({
          error: true,
          message: response.message,
          loading: false,
        });
      }
    }
  }
  handleOpen(idVivienda, idPropietario) {
    this.setState({ showModal: true });

    this.getDetalleVivienda(idPropietario, idVivienda);
    this.getInquilinoVivienda(idVivienda);
    this.getPropietariosVivienda(idVivienda);
  }
  // MUESTRA LOS DATOS DE UN UNICO INQUILINO EN FORMULARIO
  async handleInqDetalle(id_inquilino) {
    this.setState({ iNombre: "" });
    let data = { idInquilino: id_inquilino };
    const response = await request.post(
      "/viviendas/vivienda/get/inquilino",
      data
    );

    if (response && !response.error) {
      if (response.inquilino && !response.empty) {
        this.setState({
          iNombre: response.inquilino[0].nombre,
          iTelefono: response.inquilino[0].telefono,
          ifechaNac: response.inquilino[0].fecha_nacimiento,
          isexo: response.inquilino[0].sexo,
          ipassword: response.inquilino[0].password,
          inquilinoId: response.inquilino[0].id_inquilino,
        });
      } else {
        this.setState({ empty: true, message: response.message });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
      });
    }
  }
  // MUESTRA LOS DATOS DE UN UNICO propietario EN FORMULARIO
  async handlePropietarioDetalle(idPropietario) {
    let data = { idPropietario: idPropietario };

    const response = await request.post(
      "/viviendas/vivienda/get/propietario",
      data
    );

    if (response && !response.error) {
      if (response.propietarioVivienda && !response.empty) {
        this.setState({
          pDireccion: response.propietarioVivienda[0].direccion,
          pNombre: response.propietarioVivienda[0].nombre,
          pCp: response.propietarioVivienda[0].cp,
          pEstado: response.propietarioVivienda[0].estado,
          pMunicipio: response.propietarioVivienda[0].municipio,
          pTelefono: response.propietarioVivienda[0].telefono,
          pIdProp: response.propietarioVivienda[0].id_propietario,
          pIdViv: response.propietarioVivienda[0].id_vivienda,
        });
      } else {
        this.setState({ empty: true, message: response.message });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
      });
    }
  }

  handleClose() {
    this.setState({ showModal: false, edit: false, inquilinos: [] });
  }

  handleEdit() {
    this.setState({ edit: !this.state.edit });
  }

  handleAdd() {
    this.setState({ add: !this.state.add, edditInq: false });
  }

  // MUESTRA EL FORMULARIO DE EDITAR INQUILINO Y CIERRA EL DE GUARDAR INQUILINO
  handleEditInq(id_inquilino) {
    if (id_inquilino) {
      this.setState({ edditInq: !this.state.edditInq, add: false });
      this.handleInqDetalle(id_inquilino);
    } else {
      this.setState({ edditInq: !this.state.edditInq, add: false });
    }
  }
  // MUESTRA EL FORMULARIO DE EDITAR PROPIETARIO Y CIERRA EL DE GUARDAR INQUILINO
  handleEditProp(idPropietario) {
    if (idPropietario) {
      this.setState({ edditProp: !this.state.edditProp, add: false });
      this.handlePropietarioDetalle(idPropietario);
    } else {
      this.setState({ edditProp: !this.state.edditProp, add: false });
    }
  }

  handleDateChange(date, event) {
    this.setState({ startDate: date, ifechaNac: event.target.value });
  }

  // TRAE LOS DATOS DE LA VIVIENDA
  async getDetalleVivienda(idPropietario, idVivienda) {
    let data = { idPropietario: idPropietario, idVivienda: idVivienda };

    const response = await request.post("/propietarios/get/propietario", data);

    if (response && !response.error) {
      if (response.propietario && !response.empty) {
        this.setState({
          pNregistro: response.propietario.numero_registro,
          pVdireccion: response.propietario.vdireccion,
          pIdViv: response.propietario.id_vivienda,
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
      });
    }
  }
  // TRAE TODOS LOS PROPIETARIOS DE LA VIVIENDA
  async getPropietariosVivienda(idVivienda) {
    let data = { idVivienda: idVivienda };
    const response = await request.post(
      "/viviendas/vivienda/propietarios",
      data
    );

    if (response && !response.error) {
      if (response.propietariosVivienda && !response.empty) {
        this.setState({
          propietarios: response.propietariosVivienda,
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
      });
    }
  }
  // TRAE TODOS LOS INQUILINOS DE LA VIVIENDA
  async getInquilinoVivienda(idVivienda) {
    let data = { idVivienda: idVivienda };

    const response = await request.post("/servicios/getServicios", data);

    if (response && !response.error) {
      if (response.inquilinoVivienda && !response.empty) {
        this.setState({
          inquilinos: response.inquilinoVivienda,
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
      });
    }
  }
  // ELIMINAR INQUILINO DE LA VIVIENDA
  async deleteInquilino(id_inquilino, idVivienda) {
    ////console.log('idVivienda: ', idVivienda);
    // let data = { IdInquilino: id_inquilino };
    // const response = await request.post("/viviendas/inquilino/delete", data);
    // if (response && !response.error) {
    //   if (response.deleted && !response.empty) {
    //     this.getInquilinoVivienda(idVivienda);
    //   }
    //   else {
    //     this.setState({
    //       empty: true,
    //       message: response.message
    //     });
    //   }
    // } else {
    //   this.setState({
    //     error: true,
    //     message: response.message
    //   });
    // }
  }

  async limpiarValores() {
    document.getElementById("nombreInq").value = "";
    document.getElementById("telefonoInq").value = "";
    document.getElementById("passInq").value = "";
    document.getElementById("sex").value = "";
  }
  async limpiarValoresEdit() {
    document.getElementById("nameInquilinoEdit").value = "";
    document.getElementById("phoneInquilinoEdit").value = "";
    document.getElementById("passwordEdit").value = "";
    document.getElementById("sexEdit").value = "";
  }
}

export default Consumer(servicesTable);
