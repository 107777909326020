/**
 *  guests.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de Invitados
 */

import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import HousesTable from "../components/services/services-table";

import ReactModal from "react-modal";
import Request from "../core/httpClient";
const request = new Request();

class Houses extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 },
			showModal: false,
			ownerState: "0",
			fraccionamientoNombre: "",
			propietarios: [],
			viviendas: [],
			subfraccionamientos: []
		};
	}

	render() {
		return (
			<div className="houses column">
				<Helmet>
					<title>Appmosphera - Viviendas</title>
				</Helmet>
				<Header />
				<div className="justify-center">
					<div className="container column">
						<div className="white-space-16" />
						<div className="navigation align-center">
							<div className="justify-start">
								<h3 className="weight-semi">Viviendas</h3>
							</div>
							<div className="justify-end">
								<button
									type="button"
									className="btn btn-primary btn-small color-white"
									onClick={this.handleOpen.bind(this)}>
									<i className="fas fa-plus font-text" />
									&nbsp; Añadir vivienda
								</button>
							</div>
						</div>
						<div className="white-space-16" />
						<HousesTable dataHouses={this.state.viviendas} />
						<div className="white-space-16" />
						<ReactModal
							isOpen={this.state.showModal}
							className="modal modal-houses column"
							overlayClassName="overlay">
							<form
								className="flex justify-center"
								onSubmit={this.handleSubmit.bind(this)}>
								<div className="container column">
									<div className="close-modal justify-end">
										<button
											className="btn-modal-close color-dark"
											type="button"
											onClick={this.handleClose.bind(this)}>
											<i className="fas fa-times" />
										</button>
									</div>
									<h3>Añadir nueva vivienda</h3>
									<div className="white-space-8" />
									<div className="align-center">
										<p>
											<b>Dirección:</b>
										</p>
										<input
											type="text"
											name="houseAddress"
											className="input"
											placeholder="Dirección completa"
											required
											maxLength="150"
										/>
									</div>
									<div className="white-space-8" />
									<div className="align-center">
										<p>
											<b>Número/Letra:</b>
										</p>
										<input
											type="text"
											name="houseNumber"
											className="input"
											placeholder="N°/Letra de vivienda"
											required
											maxLength="8"
										/>
									</div>
									<div className="white-space-8" />
									<div className="align-center">
										<p>
											<b>Fraccionamiento:</b>
										</p>
										<input
											type="text"
											name="fraccionamiento"
											className="input"
											placeholder="Fraccionamiento"
											value={this.state.fraccionamientoNombre}
											readOnly
											disabled
										/>
									</div>
									<div className="white-space-8" />
									<div className="align-center">
										<p>
											<b>Condominio:</b>
										</p>

										<select name="idSubfrac" id="sub" className="input input-select" onChange={this.handleChange}>
											{this.state.subfraccionamientos.map((sub, key) => (
												<option key={key} value={sub.id_subfraccionamiento}>
													{sub.nombre}
												</option>

											))}
										</select>
									</div>
									<div className="white-space-8" />
									<div className="white-space-24" />
									<h4>
										Datos del propietario
										<span className="font-small">
											(opcional)
										</span>
									</h4>
									<div className="white-space-16" />
									<div className="options align-center">
										<input
											type="radio"
											value="0"
											onChange={this.handleOwner.bind(
												this
											)}
											checked={
												this.state.ownerState === "0"
											}
											name="ownerState"
											id="radio-0"
											className="radio"
										/>
										<label className="radio-label" htmlFor="radio-0">
											Ninguno
										</label>
										<input
											type="radio"
											value="1"
											onChange={this.handleOwner.bind(this)}
											checked={
												this.state.ownerState === "1"
											}
											name="ownerState"
											id="radio-1"
											className="radio"
										/>
										<label
											className="radio-label"
											htmlFor="radio-1">
											Existente
										</label>
										<input
											type="radio"
											value="2"
											onChange={this.handleOwner.bind(
												this
											)}
											checked={
												this.state.ownerState === "2"
											}
											name="ownerState"
											id="radio-2"
											className="radio"
										/>
										<label
											className="radio-label"
											htmlFor="radio-2">
											Nuevo propietario
										</label>
									</div>
									{this.state.ownerState ? (
										this.state.ownerState === "1" ? (
											<div className="column">
												<div className="white-space-16" />
												<div className="align-center">
													<p>
														<b>Propietario:</b>
													</p>
													<select
														name="idPropietario"
														className="input input-select">
														{this.state.propietarios.map(
															(
																propietario,
																key
															) => (
																	<option
																		key={key}
																		value={
																			propietario.id_propietario
																		}>
																		{
																			propietario.nombre
																		}
																	</option>
																)
														)}
													</select>
												</div>
												<div className="white-space-16" />
											</div>
										) : this.state.ownerState === "2" ? (
											<div className="column">
												<div className="white-space-16" />
												<div className="align-center">
													<p>
														<b>
															Nombre del
															propietario:
														</b>
													</p>
													<input
														type="text"
														name="fullName"
														className="input"
														placeholder="Nombre completo"
														required
														minLength="6"
														maxLength="64"
													/>
												</div>
												<div className="white-space-24" />
												<h5 className="weight-semi">
													Datos para contactar al
													propietario
												</h5>
												<div className="white-space-24" />
												<div className="align-center">
													<p>
														<b>Dirección:</b>
													</p>
													<input
														type="text"
														name="address"
														className="input"
														placeholder="Dirección de contacto"
														required
														minLength="6"
														maxLength="150"
													/>
												</div>
												<div className="white-space-8" />
												<div className="align-center">
													<p>
														<b>Municipio:</b>
													</p>
													<input
														type="text"
														name="municipality"
														className="input"
														placeholder="Municipio"
														required
														minLength="6"
														maxLength="80"
													/>
												</div>
												<div className="white-space-8" />
												<div className="align-center">
													<p>
														<b>Estado:</b>
													</p>
													<input
														type="text"
														name="state"
														className="input"
														placeholder="Estado"
														required
														minLength="6"
														maxLength="80"
													/>
												</div>
												<div className="white-space-8" />
												<div className="align-center">
													<p>
														<b>C.P:</b>
													</p>
													<input
														type="text"
														name="zip"
														className="input"
														placeholder="Código postal"
														required
														maxLength="10"
													/>
												</div>
												<div className="white-space-8" />
												<div className="align-center">
													<p>
														<b>Teléfono:</b>
													</p>
													<input
														type="text"
														name="phone"
														className="input"
														placeholder="Teléfono"
														required
														maxLength="24"
													/>
												</div>
											</div>
										) : null
									) : null}
									<div className="white-space-32" />
									<div className="btn-container">
										<button
											type="submit"
											className="btn btn-primary color-white">
											<i className="fas fa-check" />
											&nbsp; AÑADIR VIVIENDA
										</button>
										<button
											type="button"
											onClick={this.handleClose.bind(this)}
											className="btn btn-secondary color-white">
											<i className="fas fa-times" />
											&nbsp; CANCELAR
										</button>
									</div>
								</div>
							</form>
						</ReactModal>
					</div>
				</div>
			</div>
		);
	}

	componentDidMount() {
		this.load();
	}

	async load() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento
				}
			});
			this.getNombreFraccionamiento();
			this.getPropietarios();
			this.getHouses();
		}
	}

	async getNombreFraccionamiento() {
		let data = parseInt(this.state.user.idFraccionamiento);
		const response = await request.get(
			`/fraccionamientos/direccion/${data}`
		);
		if (response && !response.error) {
			if (response.fraccionamientoDireccion && !response.empty) {
				this.setState({
					fraccionamientoNombre:
						response.fraccionamientoDireccion.nombre
				});
			} else {
				this.setState({
					message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
	}

	async getPropietarios() {
		let data = {
			idFraccionamiento: this.state.user.idFraccionamiento
		};
		const response = await request.post(
			"/fraccionamientos/get/propietarios",
			data
		);

		if (response && !response.error) {
			if (response.propietario && !response.empty) {
				this.setState({
					propietarios: response.propietario
				});
			} else {
				this.setState({
					message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
	}

	// TRAER LAS VIVIENDAS DEL FRACCIONAMIENTO

	async getHouses() {
		this.setState({ loadingViviendas: true });
		let data = {
			idFraccionamiento: this.state.user.idFraccionamiento
		};
		const response = await request.post(

			"/fraccionamientos/get/viviendas",
			data
		);


		if (response && !response.error) {
			if (response.fraccionamientosTotalViviendas && !response.empty) {
				this.setState({
					viviendas: response.fraccionamientosTotalViviendas,
					loadingViviendas: false
				});


			} else {
				this.setState({
					empty: true,
					message: response.message,
					loadingViviendas: false
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
				loadingViviendas: false
			});
		}
	}

	handleOpen() {
		this.setState({ showModal: true });
		this.getSubFraccionamientos()
	}

	handleClose() {
		this.setState({ showModal: false });
	}

	handleOwner(event) {
		this.setState({ ownerState: event.target.value });
	}

	async handleSubmit(event) {
		event.preventDefault();
		const form = event.target;
		let idPropietario = 0,
			crearPropietario = 0,
			nombre = "",
			direccionPropietario = "",
			municipio = "",
			estado = "",
			cp = "",
			telefono = "";
		if (this.state.ownerState === "0") {
			idPropietario = 0;
		} else {
			if (this.state.ownerState === "1") {
				idPropietario = form.idPropietario.value;
			} else {
				idPropietario = 0;
				crearPropietario = 1;
				nombre = form.fullName.value;
				direccionPropietario = form.address.value;
				municipio = form.municipality.value;
				estado = form.state.value;
				cp = form.zip.value;
				telefono = form.phone.value;
			}
		}
		let data = {
			direccion: form.houseAddress.value,
			numeroRegistro: form.houseNumber.value,
			idFraccionamiento: this.state.user.idFraccionamiento,
			idPropietario: idPropietario,
			crearPropietario: crearPropietario,
			nombre: nombre,
			direccionPropietario: direccionPropietario,
			municipio: municipio,
			estado: estado,
			cp: cp,
			telefono: telefono
		};
		const response = await request.post("/viviendas/crear", data);
		if (response && !response.error) {
			if (response.created && !response.empty) {
				this.setState({
					reload: true,
					loading: false,
					showModal: false
				});
			} else {
				this.setState({
					empty: true,
					message: response.message,
					loading: false,
					showModal: false
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
				loading: false,
				showModal: false
			});
		}
		this.getHouses();
	}
	// traer subfraccionamientos
	async getSubFraccionamientos() {
		let data = {
			idFraccionamiento: this.state.user.idFraccionamiento
		};

		const response = await request.post(

			"/fraccionamientos/subfraccionamientos/get/all",
			data
		);

		if (response && !response.error) {
			if (response.subfraccionamientos && !response.empty) {
				this.setState({
					subfraccionamientos: response.subfraccionamientos,
					idSubfrac: response.subfraccionamientos[0].id_subfraccionamiento

				});
				////console.log(this.state.idSubfrac);



			} else {
				this.setState({
					empty: true,
					message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}

	}
}

export default Consumer(Houses);
